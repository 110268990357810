<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M26,32 L32,32 L32,26 L26,26 M26,23 L32,23 L32,17 L26,17 M17,14 L23,14 L23,8 L17,8 M26,14 L32,14 L32,8 L26,8 M17,23 L23,23 L23,17 L17,17 M8,23 L14,23 L14,17 L8,17 M8,32 L14,32 L14,26 L8,26 M17,32 L23,32 L23,26 L17,26 M8,14 L14,14 L14,8 L8,8 L8,14 Z"
    />
  </svg>
</template>
<script>
import { SHOW_ALL_ICON } from '@/constants'
export default {
  name: SHOW_ALL_ICON,
}
</script>
